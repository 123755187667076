<template>
  <div class="graphic">
    <!--搜索-->
    <a-card class="search-bar">
      <a-form-model ref="searchForm" :model="searchForm">
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-model-item label="查询1" prop="name">
              <a-input
                v-model.trim="searchForm.name"
                allow-clear
                placeholder="选择"
                @keyup.native.enter="search()"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="查询2" prop="wt_id">
              <a-select
                v-model="searchForm.wt_id"
                allow-clear
                placeholder="选择"
              >
                <a-select-option v-for="level in levels" :key="level.kid">
                  {{ level.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-button :loading="loading" type="primary" @click="search()">
                查 询
              </a-button>
              <a-button @click="reset()">重 置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <!--表格-->
    <a-card class="graphic-table">
      <template slot="title">
        <span class="table-title">图文列表</span>
        <a-button type="primary" icon="plus" @click="showModal('新增')">
          新增
        </a-button>
      </template>
      <a-table
        rowKey="kid"
        :loading="loading"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange"
      >
        <a slot="action" slot-scope="record">
          <a-button type="link" @click="showModal('查看')">查看</a-button>
          <a-button type="link" @click="showModal('编辑')">编辑</a-button>
          <a-popconfirm
            title="您确认要删除该课件吗？"
            @confirm="del(record.kid)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </a>
      </a-table>
    </a-card>
    <!--查看-->
    <a-modal
      v-model="visible"
      width="60%"
      :destroyOnClose="true"
      :title="title"
      :footer="null"
    >
      <graphicEditor :disabled="disabled"></graphicEditor>
    </a-modal>
  </div>
</template>

<script>
import graphicEditor from "@/components/graphic/graphic-editor";
export default {
  name: "graphic",
  components: {
    graphicEditor,
  },
  data() {
    return {
      //搜索字段
      searchForm: {
        name: "",
        wt_id: "",
      },
      //选择器-数据
      workTypes: [],
      //搜索 loading
      loading: false,
      //搜索-筛选
      levels: [],
      //table-数据
      datalist: [],
      //table-配置
      columns: [
        {
          title: "序号",
          key: "kid",
          dataIndex: "kid",
          align: "center",
          width: 65,
          customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}`
        },
        { title: "1xxx", key: "name", dataIndex: "name", ellipsis: true },
        {
          title: "2xxx",
          key: "sort",
          dataIndex: "sort",
          align: "center",
          ellipsis: true,
        },
        {
          title: "3xxx",
          key: "level",
          dataIndex: "level",
          align: "center",
          ellipsis: true,
        },
        {
          title: "4xxx",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        // { title: '5', key: 'wt_str', dataIndex: 'wt_str', align: 'center', ellipsis: true },
        // { title: '6', key: 'wt_link', dataIndex: 'wt_link', align: 'center', ellipsis: true },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      //table-分页
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "50"],
        current: 1,
        pageSize: 8,
        total: 0,
        showTotal: (total) => `共 ${total} 条`,
      },
      //弹窗-状态,
      title: "",
      visible: false,
      //查看
      disabled: false,
    };
  },
  mounted() {
    //初始化数据
    this.getCourseList();
    //搜索-筛选
    this.allLevels();
  },
  methods: {
    //搜索
    search() {
      console.log(this.searchForm);
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.getCourseList();
    },
    //搜索-重置
    reset() {
      this.$refs.searchForm.resetFields();
    },
    //分页
    onPageChange(e) {
      this.pagination = e;
      this.searchForm.page = e.current;
      this.searchForm.list_rows = e.pageSize;
      this.getCourseList();
      console.log("分页", e);
    },
    del(e) {
      console.log("删除", e);
    },
    //请求
    getCourseList() {
      this.loading = true;
      this.api
        .getCourseList(this.searchForm)
        .then((res) => {
          console.log("res", res);
          this.datalist = res.data.data;
          this.pagination.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //搜索-筛选
    allLevels() {
      this.api.allLevels({ level_id: 1 }).then((res) => {
        this.levels = res.data.data;
      });
    },
    //弹窗-开
    showModal(e) {
      this.title = e;
      if (e === "查看") {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.visible = true;
    },
  },
};
</script>

<style scoped lang="less"></style>
