<template>
  <div class="editor">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="标题" prop="name">
        <a-input
          v-model="form.name"
          allow-clear
          placeholder="请输入标题"
          :disabled="disabled"
          @blur="
            () => {
              $refs.name.onFieldBlur();
            }
          "
        />
        <!--                <span>{{form.name.length}}/{{64-form.name.length}}</span>-->
      </a-form-model-item>
      <a-form-model-item ref="author" label="作者" prop="author">
        <a-input
          v-model="form.author"
          allow-clear
          placeholder="请输入作者"
          :disabled="disabled"
          @blur="
            () => {
              $refs.author.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="thebody" label="正文" prop="thebody">
        <a-textarea
          v-model="form.thebody"
          :disabled="disabled"
          allow-clear
          placeholder="请输入正文"
          type="textarea"
          :rows="14"
        />
      </a-form-model-item>
      <a-form-model-item ref="CoverSummary" label="封面" prop="CoverSummary">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :before-upload="beforeUpload"
          :disabled="disabled"
          @change="handleChange"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">选择封面</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item ref="CoverSummary" label="摘要" prop="CoverSummary">
        <a-textarea
          v-model="form.summary"
          :disabled="disabled"
          allow-clear
          placeholder="选填，摘要会在订阅号消息、转发链接等文章外的场景显露，帮助读者快速了解内容，如不填写则默认抓取正文前54字"
          type="textarea"
          :rows="4"
        />
      </a-form-model-item>
      <a-form-model-item label="原创声明" prop="delivery">
        <a-switch
          v-model="form.delivery"
          :disabled="disabled"
          checked-children="开"
          un-checked-children="关"
          default-checked
        />
      </a-form-model-item>
      <a-form-model-item label="文章设置" prop="type">
        <a-checkbox-group
          v-model="form.type"
          name="type"
          :disabled="disabled"
          :options="options"
          @change="onCheckbox"
        />
        <span v-if="inputShow">
          <a-input
            v-model="form.name2"
            allow-clear
            placeholder="请输入链接"
            :disabled="disabled"
            @blur="
              () => {
                $refs.name2.onFieldBlur();
              }
            "
          />
        </span>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 确认 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm"> 重置 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  name: "graphic-editor",
  props: {
    disabled: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      //多选
      options: [
        { label: "原文链接", value: "1" },
        { label: "话题标签", value: "2" },
      ],
      //input 是否显示
      inputShow: false,
      //表单
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        name: "",
        name2: "",
        author: "",
        thebody: "",
        summary: "",
        delivery: false,
        type: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 3, max: 64, message: "输入长度为3到64", trigger: "blur" },
        ],
        author: [
          { required: true, message: "请输入作者", trigger: "blur" },
          { min: 2, max: 8, message: "输入长度为2到8", trigger: "blur" },
        ],
        thebody: [{ required: true, message: "请输入正文", trigger: "blur" }],
        summary: [{ required: true, message: "请输入摘要", trigger: "blur" }],
        type: [
          {
            type: "array",
            required: false,
            message: "请输入类型",
            trigger: "change",
          },
        ],
      },
      //上传
      loading: false,
      imageUrl: "",
    };
  },
  methods: {
    //确认
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //上传
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    //多选
    onCheckbox(e) {
      console.log("e", e);
      if (e[0] === "1") {
        this.inputShow = true;
      } else {
        this.inputShow = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.editor {
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
</style>